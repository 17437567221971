<template>
  <div>
    <div class="userinfo">
      <div class="storeCenter_item_top">
        <div class="storeCenter_item_top_left">
          <div class="head_img">
            <!-- info.headImgUrl -->
            <img :src="require('@/assets/image/Group 2821.png')" alt="" />
          </div>
          <div class="storeCenter_item_top_left_flex">
            <div class="head_name">
              <span style="font-weight: 550">{{ info.realName }}</span>

              <span class="info_type1" v-if="info.type == 0">普通用户</span>
              <span class="info_type2" v-if="info.type == 1">秘书长用户</span>
              <span class="info_type3" v-if="info.type == 2">秘书长会员用户</span>
              <span class="info_sex">{{ info.sex }}</span>

              <!-- <span style="display:inline-block; font-size: 10px; color: #ff73e0; background: #ffe8fa; padding: 3px 5px;">女士</span> -->
            </div>
            <div class="txt">
              <span>{{ info.chamberCommerceName }}</span>
            </div>
            <div class="txt">
              <img src="../../../assets/image/jr-icon-gongsi.png" alt="" />

              <span>{{ info.companyName }}</span>
            </div>
            <div class="txt">
              <img
                style="position: relative; top: 2px"
                src="../../../assets/image/jr-icon-qiye.png"
                alt=""
              />

              <span>{{ info.companyJob }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="store_table">
        <div
          class="store_table_item"
          v-for="(item, ii) in orderType"
          :key="ii"
          @click="storeClick(item.id)"
          :class="item.id === typeIndex ? 'active' : ''"
        >
          {{ item.code }}
        </div>
      </div>
      <div v-if="typeIndex == 1" style="background: #ffffff; padding: 20px">
        <div class="input-with">
          <el-input
            placeholder="请输入企业名称"
            v-model="queryInfo.companyName"
            class="input-with-select"
          >
            <el-button class="seach" slot="append" @click="getCompany()">搜索</el-button>
          </el-input>
        </div>
        <el-table
          v-loading="loading"
          :data="listData"
          stripe
          style="width: 100%"
          class="in_table"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          />
          <el-table-column label="编号" align="center" type="index">
            <template slot-scope="scope">
              <span>{{ (queryInfo.pageNum - 1) * queryInfo.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="logo" align="center">
            <template v-slot="{ row }">
              <img :src="row.sharePictures" width="36px" height="36px" alt="" />
            </template>
          </el-table-column>
          <el-table-column label="企业名称" prop="companyFullName" align="center"></el-table-column>
          <el-table-column label="所在城市" prop="city" align="center"></el-table-column>
          <el-table-column label="企业标签" prop="companyFullName" align="center">
            <template v-slot="{ row }">
              <span v-for="(o, index) in row.labelName" :key="index">
                <span style="color: #4e93fb; padding: 0px 5px">#{{ o }}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="企业简介" prop="content" align="center">
            <template v-slot="{ row }">
              <span class="company_intro">{{ row.enterpriseIntro }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            class-name="small-padding fixed-width"
            width="260px"
          >
            <template slot-scope="scope">
              <el-button style="color: #146aff" type="text" @click="editclick(scope.row)"
                >详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="new_page">
          <el-pagination
            :current-page="queryInfo.pageNum"
            :page-sizes="[10, 30, 50]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
      <div v-if="typeIndex == 2" style="background: #ffffff; padding: 20px">
        <div class="input-with">
          <el-input
            placeholder="请输入项目名称"
            v-model="queryInfo.projectName"
            class="input-with-select"
          >
            <el-button class="seach" slot="append" @click="getInspectDemand()">搜索</el-button>
          </el-input>
        </div>
        <el-table
          v-loading="loading"
          :data="listData"
          stripe
          style="width: 100%"
          class="in_table"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          />
          <el-table-column label="编号" align="center" type="index">
            <template slot-scope="scope">
              <span>{{ (queryInfo.pageNum - 1) * queryInfo.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>

          <el-table-column label="项目名称" prop="projectName" align="center"></el-table-column>
          <el-table-column label="项目时间" prop="questionnaireName" align="center">
            <template v-slot="{ row }">
              <span>{{ row.startTime }}-{{ row.endTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="项目目的" prop="projectPurpose" align="center">
            <template v-slot="{ row }">
              <span v-if="row.submitState == 0">已保存</span>
              <span v-if="row.submitState == 1">已提交</span>
            </template>
          </el-table-column>
          <el-table-column label="项目地点" prop="content" align="center">
            <template v-slot="{ row }">
              <span>{{ row.region }}</span>
            </template>
          </el-table-column>
          <el-table-column label="考察人数" prop="content" align="center">
            <template v-slot="{ row }">
              <span>{{ row.inspectNumber }}</span>
            </template>
          </el-table-column>
          <el-table-column label="考察领域" prop="content" align="center">
            <template v-slot="{ row }">
              <span v-for="(o, index) in row.areasInvestigation" :key="index">
                <span style="color: #4e93fb; padding: 0px 5px">#{{ o.areas[1] }}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            class-name="small-padding fixed-width"
            width="260px"
          >
            <template slot-scope="scope">
              <el-button style="color: #146aff" type="text" @click="torecord(scope.row)"
                >详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="new_page">
          <el-pagination
            :current-page="queryInfo.pageNum"
            :page-sizes="[10, 30, 50]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
      <div v-if="typeIndex == 3" style="background: #ffffff; padding: 20px">
        <div class="input-with">
          <el-input
            placeholder="请输入用户昵称"
            v-model="queryInfo.wechatUserName"
            class="input-with-select"
          >
            <el-button class="seach" slot="append" @click="getAssociatedUser()">搜索</el-button>
          </el-input>
        </div>
        <el-table
          v-loading="loading"
          :data="listData"
          stripe
          style="width: 100%"
          class="in_table"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          />
          <el-table-column label="编号" align="center" type="index">
            <template slot-scope="scope">
              <span>{{ (queryInfo.pageNum - 1) * queryInfo.pageSize + scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="头像" align="center">
            <template v-slot="{ row }">
              <img :src="row.headImgUrl" width="36px" height="36px" alt="" />
            </template>
          </el-table-column>
          <el-table-column label="姓名" prop="realName" align="center"></el-table-column>
          <el-table-column label="职务" prop="job" align="center"></el-table-column>
          <el-table-column label="手机号" prop="companyFullName" align="center">
            <template v-slot="{ row }">
              <span>{{ row.phone }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" prop="content" align="center">
            <template v-slot="{ row }">
              <span>{{ row.createTime }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            class-name="small-padding fixed-width"
            width="260px"
          >
            <template slot-scope="scope">
              <el-button style="color: #146aff" type="text" @click="userinfoClick(scope.row)"
                >详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="new_page">
          <el-pagination
            :current-page="queryInfo.pageNum"
            :page-sizes="[10, 30, 50]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userinfo, getAssociatedUser, getCompany, getInspectDemand } from '@/api/wechatUser'
const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  companyName: null, //企业名称
  field: null, //排序字段
  projectName: null, //项目名称
  sort: 'DESC', //排序方式（ASC：正序，DESC：倒序）
  state: '', //审核状态（0：待审核；1：已审核）
  wechatUserId: null, //用户id
  wechatUserName: null //用户名称
})
export default {
  name: 'userinfo',
  data() {
    return {
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      // 遮罩层
      loading: false,
      // 总条数
      total: 0,
      orderType: [
        {
          id: 1,
          code: '关联企业'
        },
        {
          id: 2,
          code: '关联项目'
        },
        {
          id: 3,
          code: '关联用户'
        }
      ],
      typeIndex: 1,
      id: this.$route.query.id * 1,
      info: {}
    }
  },
  created() {
    this.userinfo()
    this.getCompany()
  },
  methods: {
    storeClick(id) {
      this.typeIndex = id
      if (id == 1) {
        this.getCompany()
      } else if (id == 2) {
        this.getInspectDemand()
      } else if (id == 3) {
        this.getAssociatedUser()
      }
    },
    //获取用户详情
    async userinfo() {
      const res = await userinfo(this.id)
      if (res.data.resultCode == 200) {
        this.info = res.data.data
      }
    },
    //获取关联用户信息
    async getAssociatedUser() {
      this.queryInfo.wechatUserId = this.id
      this.listData = []
      const res = await getAssociatedUser(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list
        this.total = res.data.data.total
      }
    },
    //获取关联企业信息
    async getCompany() {
      this.queryInfo.wechatUserId = this.id
      this.listData = []
      const res = await getCompany(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list
        this.total = res.data.data.total
        this.listData.forEach((item) => {
          if (item.labelName != null) {
            item.labelName = item.labelName.split(',')
          }
        })
      }
    },
    //获取关联项目信息
    async getInspectDemand() {
      this.queryInfo.wechatUserId = this.id
      this.listData = []
      const res = await getInspectDemand(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list

        this.listData.forEach((o) => {
          if (o.areasInvestigation != null) {
            o.areasInvestigation = JSON.parse(o.areasInvestigation)
          }
        })

        this.total = res.data.data.total
      }
    },
    //编辑企业
    editclick(item) {
      this.$router.push({
        name: 'addenterprise',
        query: { id: item.id }
      })
    },

    //编辑项目
    torecord(item) {
      this.$router.push({
        name: 'addinvestigate',
        query: { id: item.id }
      })
    },
    //关联用户详情
    userinfoClick(item) {
      this.$router.push({
        name: 'userinfo',
        query: { id: item.id }
      })
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.userinfo {
  width: 100%;
  .storeCenter_item_top {
    width: 100%;
    background: #ffffff;
    padding: 10px;
    .storeCenter_item_top_left {
      display: flex;
      align-items: center;
      .head_img {
        width: 100px;
        height: 100px;
      }
      img {
        width: 100%;
        height: 100%;
      }
      .storeCenter_item_top_left_flex {
        line-height: 18px;
        .head_name {
          padding-left: 10px;
        }
        .info_type1 {
          display: inline-block;
          font-size: 10px;
          background: #edf4ff;
          color: #4e93fb;
          padding: 3px 5px;
          margin-left: 10px;
        }
        .info_type2 {
          display: inline-block;
          font-size: 10px;
          background: #fff2e8;
          color: #ff7d18;
          padding: 3px 5px;
          margin-left: 10px;
        }
        .info_type3 {
          display: inline-block;
          font-size: 10px;
          background: #e8fff1;
          color: #25ff7c;
          padding: 3px 5px;
          margin-left: 10px;
        }
        .info_sex {
          display: inline-block;
          font-size: 10px;
          background: #ffe8fa;
          color: #ff18cc;
          padding: 3px 5px;
          margin-left: 10px;
        }
        .txt {
          padding: 3px 10px;
          font-size: 12px;
          span {
            margin-left: 5px;
          }
          img {
            width: 15px;
            height: 13px;
          }
        }
      }
    }
  }
  .store_table {
    padding: 20px;
    width: 100%;
    overflow: hidden;
    height: 48px;
    line-height: 48px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #ffffff;
    margin-top: 20px;
    .store_table_item {
      padding: 0px 20px;
      overflow: hidden;
      line-height: 48px;
      box-sizing: border-box;
      color: #1f2d3d;
      cursor: pointer;
      text-align: center;
      font-weight: 400;
      font-size: 14px;
    }

    .active {
      border-bottom: 2px solid #4e93fb;
      color: #4e93fb;
      background-color: #fff;
    }
  }

  .span_div {
    font-size: 14px;
    color: #7c7f8e;
    margin-right: 12px;
  }
  .company_intro {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5; //行数需设置
    line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  .input-with {
    width: 100%;
    overflow: hidden;
    padding-top: 30px;

    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }

  .in_table {
    width: 100%;
    margin-top: 20px;

    .img_url {
      width: 42px;
      height: 42px;
      border-radius: 4px;
    }
  }

  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }
}
</style>
